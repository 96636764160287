import { motion, AnimatePresence } from "framer-motion"
import { useState, useEffect, useRef } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import Snowfall from 'react-snowfall'
import Countdown from 'react-countdown';
import useSound from 'use-sound';
import Div100vh from 'react-div-100vh'


import './App.scss';
import { Button, ProgressBar } from 'react-bootstrap';
import imgButton from './assets/img/button.png';
import imgLogo from './assets/img/logo.svg';
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import music from './assets/sounds/push.mp3';


const data = [

  {"id": 0, "title": "Ne pritisni tega gumba.", "count": 0 },
  {"id": 1, "title": "Resno?! Od sedaj naprej ne pritiskaj več. OK?!", "count": 1 },
  {"id": 2, "title": "Ho ho, nekdo je za igro 😈 Čim hitreje pritisni spodnji gumb! 👇🏼", "count": 2 },
  {"id": 3, "title": "Opa strela! Se pardoniramo. No, pritisni ga 30x!", "count": 5 },
  {"id": 4, "title": "Ok, ok ... kolikokrat ga lahko pritisneš v 10 sekundah?", "count": 100 },
  {"id": 5, "title": "Tsss ... keyboard superhero, kaj 🦸‍♀️ Če ga pritisneš 5x, ti povemo skrivnost.", "count": 105 },
  {"id": 6, "title": "Te zanima kaj je skrivnost?", "count": 106 },
  {"id": 7, "title": "Skrivnost je … ", "count": 107 },
  {"id": 8, "title": "Do konca je 2022 klikov! Sori. \n Ampak je vredno 🎁", "count": 108 },
  {"id": 9, "title": "Pritisni rdeč gumb", "count": 109 },
  {"id": 10, "title": "Pazi ...", "count": 110 },
  {"id": 11, "title": "zdej ...", "count": 111 },
  {"id": 12, "title": "to ...", "count": 112 },
  {"id": 13, "title": "Pritisni velik rdeč gumb … O je je 😉 ", "count": 113 },
  {"id": 14, "title": "Ufff, iz nič na šest. Le kateri je pravi? Klik, klik, klik!", "count": 114 },
  {"id": 15, "title": "Pa zdaj? 1 pritisk za 180 klikov ... najdeš pravega?", "count": 140 },
  {"id": 16, "title": "Wopaaa! Bravo! Naslednjih 5 klikov je vrednih 50.", "count": 141 },
  {"id": 17, "title": "Oleee🎉 Si že čez 500. Še malo. Ajde, nežno ga stisni 10x!", "count": 650 },
  {"id": 18, "title": "650! A ga ujameš? Nope. 1 klik ne bo dost 😉 ", "count": 1 },
  {"id": 19, "title": "Bravo! Če ga zdele, ti damo 150 bonus klikov! Samo zdele ⏱", "count": 1 },
  {"id": 20, "title": "Samo danes nora ponudba! Za 50 jih dobiš še 50 klikov 🙃", "count": 1 },
  {"id": 21, "title": "Ojoj, kaj sedaj?! Poišči pravega in dobiš še 10x toliko kot je gumbov. ", "count": 1 },
  {"id": 22, "title": "Pred tabo so trije. 40x stisni tistega v Escapebox barvi 🤔", "count": 1 },
  {"id": 23, "title": "Te zanima kok ti manjka do polovice? 17 pritiskov za ogled! ", "count": 1 },
  {"id": 24, "title": "In smooooo čez polovicooo. Od veselja ga klikni 30x 🎉", "count": 1 },
  {"id": 25, "title": "Zdej se ti ne splača več odnehat. Ujami gumb in 49 klikov je tvojih.", "count": 1 },
  {"id": 26, "title": "Prihaja nova zanimivost. ", "count": 1 },
  {"id": 27, "title": "Svetovni rekord v klikanju s kazalcem je 1092x v eni minuti. Tok jih imaš ti 🥳 ", "count": 1 },
  {"id": 28, "title": "Verjetno je šla kakšna minuta več, ampak brez skrbi, to so profiji", "count": 1 },
  {"id": 29, "title": "Za nagrado ti podarimo še 80 bonus klikov!", "count": 1 },
  {"id": 30, "title": "Zdej pa ena hitrostna dirka ... ready, steady, klik!", "count": 1 },
  {"id": 31, "title": "Najprej 40 klikov, desno zgoraj. 🤸🏼", "count": 1 },
  {"id": 32, "title": "In gremooo 40 klikov levo spodaj. 🤸🏼", "count": 1 },
  {"id": 33, "title": "Števec raste, ti pa si že pridno bližaš koncu. Gremo še 40 klikov! 💪🏼", "count": 1 },
  {"id": 34, "title": "No zdej ko so se razgibale tvoje ročne mišice, pa spet malo počitka. Katere barve je tale napis?", "count": 1 },
  {"id": 35, "title": "Katere barve gumb moraš konstantno klikat?", "count": 1 },
  {"id": 36, "title": "Novih 10 sekund, da si daš duška in se naklikaš!", "count": 1 },
  {"id": 37, "title": "Klikni 5x, če je klikanje naporno.", "count": 1 },
  {"id": 38, "title": "Pa te vseeno zanima kaj je na koncu?", "count": 1 },
  {"id": 39, "title": "Ali je sploh karkoli vredno 2022 klikov?", "count": 1 },
  {"id": 40, "title": "Je pa res, da si samo še 317 klikov stran.", "count": 1 },
  {"id": 41, "title": "Ajde, ti podarimo 200 bonus klikov pa roka!", "count": 1 },
  {"id": 42, "title": "Zdej pa konc heca. Dejansko imaš še čisto malo klikov do zmage.", "count": 1 },
  {"id": 43, "title": "Preden se igra konča, še 3 kliki za programerja, ki je moral čez to igro veliko večkrat kot ti 😂", "count": 1 },
  {"id": 44, "title": "Če ujameš ta gumb, boš vstopil v leto 2022.", "count": 1 },
  {"id": 45, "title": "A nam ne verjameš? Kdaj smo ti še dal razlog, da nam ne zaupaš?", "count": 1 },
  {"id": 46, "title": "2000!!! Smo ti rekli, da smo resni! 12 klikov te loči do 10 sekundnega odštevanja v novo leto ...", "count": 1 },
  {"id": 47, "title": "Din don! Zadnjih 10 klikov v manj kot 10 sekundah! Ja?", "count": 1 },
  {"id": 48, "title": "Ooooooohoooohoooo, lej k ti je res uspelo! 🥳 ", "count": 1 },
  {"id": 49, "title": "Video", "count": 1 },
  {"id": 50, "title": "OMG 🎉🎉 Nismo verjeli, da je kdo res tako nor, da bo priklikal do konca ❤️ Tvoj je \n <strong>Certifikat najbolj (ne)kakovostno porabljenega časa v letu 2021!</strong> Za nagrado ti ne podarjamo ničesar 🤷🏼 Razen če nam zaupaš kdo si. Potem te 10. 1. 2022 morda izžrebamo za unikatno nagrado. Poskusi svojo (ne)srečo:", "count": 1 }

  /*
  {"id": 0, "title": "Ne pritisni tega gumba.", "count": 0 },
  {"id": 1, "title": "Resno?! Od sedaj naprej ne pritiskaj več. OK?!", "count": 1 },
  {"id": 2, "title": "Nekdo je za igro! Čim hitreje pritisni gumb! 👇🏼", "count": 2 },
  {"id": 3, "title": "Opa strela! Se pardoniramo. No, pa ga pritisni 30x!", "count": 5 },
  {"id": 4, "title": "Ok, kolikokrat pa ga lahko pritisneš v 10 sekundah?", "count": 100 },
  {"id": 5, "title": "Wooow, bravo, ti računalniška antilopa ti. Če pritisneš še 5x, ti že moramo povedati skrivnost.", "count": 105 },
  {"id": 6, "title": "Te zanima kaj je skrivnost?", "count": 106 },
  {"id": 7, "title": "Skrivnost je … najbolje, da pritisneš in pogledaš.", "count": 107 },
  {"id": 8, "title": "Do konca je 2022 klikov. Sori. \n Ampak, lahko greva bolj igrivo. Pritisni in poglej.", "count": 108 },
  {"id": 9, "title": "Pritisni rdeč gumb", "count": 109 },
  {"id": 10, "title": "Pazi ...", "count": 110 },
  {"id": 11, "title": "zdej ...", "count": 111 },
  {"id": 12, "title": "to ...", "count": 112 },
  {"id": 13, "title": "Pritisni velik rdeč gumb … O je je!", "count": 113 },
  {"id": 14, "title": "Ufff, iz nič na šest. Če klikneš na pravilen gumb, dobiš 60 dodatnih klikov. Le kateri gumb je pravi? Klik, klik!", "count": 114 },
  {"id": 15, "title": "Pa zdaj? Za 180 klikov ... najdeš pravega?", "count": 140 },
  {"id": 16, "title": "Wopaaa! Bravo! Naslednjih 5 klikov je vrednih 50.", "count": 141 },
  {"id": 17, "title": "Čestitke! Števec klikov je pokazal, da si že čez 500. Še malo. Gremo na klasično igro. Klikni gumb 10x!", "count": 650 },
  {"id": 18, "title": "650! A ga lahko ujameš? Nope. 1 klik ni dovolj 😉 ", "count": 1 },
  {"id": 19, "title": "Bravo! Če nadaljuješ, te nagradimo s 150 bonus kliki!", "count": 1 },
  {"id": 20, "title": "Nadaljujemo z nagradami. Samo danes nora ponudba! Za 50 klikov dobiš 50 klikov. 🙃", "count": 1 },
  {"id": 21, "title": "Z malce prakse od prej bo še lažje. Klikni na pravi gumb in pridobi 10x toliko klikov kot je gumbov. ", "count": 1 },
  {"id": 22, "title": "Pred tabo so trije gumbi. 40x klikni na gumb, ki se ujema z barvo Escapebox loga.", "count": 1 },
  {"id": 23, "title": "A te zanima koliko manjka do polovice? Klikni 17x! ", "count": 1 },
  {"id": 24, "title": "In smooooo čez polovicooo. Od veselja klikni 30x! 🎉", "count": 1 },
  {"id": 25, "title": "Zdej se ti ne splača več odnehat. Ujami gumb in 49 klikov je tvojih.", "count": 1 },
  {"id": 26, "title": "Prihaja nova zanimivost. ", "count": 1 },
  {"id": 27, "title": "Svetovni rekord v klikanju s kazalcem je 1092x v eni minuti. In točno na toliko si ti zdaj. 🥳  Hitro po nov klik!", "count": 1 },
  {"id": 28, "title": "Verjetno je šla kakšna minuta več, ampak ni panike, tisto je res zgolj za profesionalce, ki so več kot 8 ur na ekranih.  ", "count": 1 },
  {"id": 29, "title": "Za nagrado, ker ne preživljaš toliko ur na ekranih, ti podarimo še 80 bonus klikov!", "count": 1 },
  {"id": 30, "title": "Zdej pa ena hitrostna dirka ... ready, steady, klik!", "count": 1 },
  {"id": 31, "title": "Najprej 40 klikov, desno zgoraj. 🤸🏼", "count": 1 },
  {"id": 32, "title": "In gremooo 40 klikov levo spodaj. 🤸🏼", "count": 1 },
  {"id": 33, "title": "Števec raste, ti pa si že pridno bližaš koncu. Gremo še 40 klikov! 💪🏼", "count": 1 },
  {"id": 34, "title": "No zdej ko so se razgibale tvoje ročne mišice, pa spet malo počitka. Katere barve je tale napis?", "count": 1 },
  {"id": 35, "title": "Katere barve gumb moraš konstantno klikat v tej nagajivi igri?", "count": 1 },
  {"id": 36, "title": "Novih 10 sekund, da si daš duška in se naklikaš!", "count": 1 },
  {"id": 37, "title": "Klikni 5x, če je klikanje naporno.", "count": 1 },
  {"id": 38, "title": "Pa te vseeno zanima kaj je na koncu?", "count": 1 },
  {"id": 39, "title": "Ali je sploh karkoli vredno 2022 klikov?", "count": 1 },
  {"id": 40, "title": "Je pa res, da si samo še 317 klikov stran.", "count": 1 },
  {"id": 41, "title": "Ajde, ti podarimo 200 bonus klikov pa roka!’’", "count": 1 },
  {"id": 42, "title": "Zdej pa konc heca. Dejansko imaš še čisto malo klikov do zmage!", "count": 1 },
  {"id": 43, "title": "Preden se igra konča, še 3 kliki za programerja, ki je moral čez to igro veliko večkrat kot ti. Hip hip hooray!", "count": 1 },
  {"id": 44, "title": "Če ujameš ta gumb, boš vstopil v leto 2022.", "count": 1 },
  {"id": 45, "title": "A nam ne verjameš? Kdaj smo ti še dal razlog, da nam ne zaupaš?", "count": 1 },
  {"id": 46, "title": "2000!!! Smo ti rekli, da smo resni! 12 klikov te loči do 10 sekundnega odštevanja v novo leto ...", "count": 1 },
  {"id": 47, "title": "Din don! Zadnjih 10 klikov v 10 sekundah!", "count": 1 },
  {"id": 48, "title": "Ooooooohoooohoooo, lej k ti je res uspelo! 🥳 ", "count": 1 },
  {"id": 49, "title": "Video", "count": 1 },
  {"id": 50, "title": "Čestitamo. Ker nismo verjeli, da je kdo res tako nor (pa hvala ker si ❤️ ), da bo priklikal do konca, bi radi tvoje ime za večne čase ovekovečili. Če nam zaupaš svoje ime in podjetje, jih bomo obesili na veličastni wall of fame v naši pisarni. PS: Tvojih podatkov ne bomo uporabljali v druge namene.", "count": 1 }
  */

]

function Home() {

  const [play] = useSound(music, { volume: 0.5 });


  return (
    <>

      <Div100vh>
      <div className="start">
        <div class="container text-center">
        <p>Letos ne bi radi tratli tvojega časa, zato smo naredili eno čisto enostavno voščilnico.</p>

        <p>Vsi za novo leto govorijo naredi to, naredi ono, no, mi ti bomo rekli ravno nasprotno. Česa ne naredi. Pa se poskusi zadržat 😉 </p>

        <p>TO JE ZATE! 🥂</p>
        <p>Želimo ti srečno 2022 in še na veliko igrivih projektov! 👾 </p>

        

        <Link to="/igra"><motion.span whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }} onClick={play} className="play-btn">ZAIGRAJ</motion.span></Link>
        </div>
        <a onClick={play} href="https://www.escapebox.si" target="_blank"><img className="logo" src={imgLogo}  /></a>
      </div>
      </Div100vh>
      
    </>
  );
}

function Game() {


  const [counter, setCounter] = useState(0);
  const [counterLoc, setCounterLoc] = useState(10);
  const [msg, setMsg] = useState(data[0].title);
  const [loading, setLoading] = useState(false);
  const [booster, setBooster] = useState(false);
  const [boosterValue, setBoosterValue] = useState();
  const [spree, setSpree] = useState(false);
  const [intervalval, setIntervalval] = useState();
  const [showVideo, setShowVideo] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [date, setDate] = useState();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false)
  
  const appRef = useRef();
  const buttonRef = useRef();
  const contentRef = useRef();

  const [play] = useSound(music, { volume: 0.1 });

  
  


  const loader = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 600);

    setTimeout(() => {
      setBooster(false)
    }, 1000);

  }


  

  const clickHandler = () => {


    let width = window.innerWidth;
    let height = window.innerHeight;
    const body = document.body;
    
    const elButton = document.querySelector(".action-button");
    const elWrapper = document.querySelector(".action-button");
    
    function getRandomArbitrary(min, max) {
      return Math.random() * (max - min) + min;
    }
    
    function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    

    const treatmojis = ["🥶", "🏂", "🏔️", "❄️", "⛄", "🍪", "🎿","🧦","⛸","👹"];
    const treats = [];
    const radius = 15;
    
    const Cd = 0.47; // Dimensionless
    const rho = 1.22; // kg / m^3
    const A = Math.PI * radius * radius / 10000; // m^2
    const ag = 9.81; // m / s^2
    const frameRate = 1 / 60;
    

    function createTreat() /* create a treat */ {
      const vx = getRandomArbitrary(-10, 10); // x velocity
      const vy = getRandomArbitrary(-10, 1);  // y velocity
      
      const el = document.createElement("div");
      el.className = "treat";
    
      const inner = document.createElement("span");
      inner.className = "inner";
      inner.innerText = treatmojis[getRandomInt(0, treatmojis.length - 1)];
      el.appendChild(inner);
      
      elWrapper.appendChild(el);
    
      const rect = el.getBoundingClientRect();
    
      const lifetime = getRandomArbitrary(2000, 3000);
    
      el.style.setProperty("--lifetime", lifetime);
    
      const treat = {
        el,
        absolutePosition: { x: rect.left, y: rect.top },
        position: { x: rect.left, y: rect.top },
        velocity: { x: vx, y: vy },
        mass: 0.1, //kg
        radius: el.offsetWidth, // 1px = 1cm
        restitution: -.7,
        
        lifetime,
        direction: vx > 0 ? 1 : -1,
    
        animating: true,
    
        remove() {
          this.animating = false;
          this.el.parentNode.removeChild(this.el);
        },
    
        animate() {
          const treat = this;
          let Fx =
            -0.5 *
            Cd *
            A *
            rho *
            treat.velocity.x *
            treat.velocity.x *
            treat.velocity.x /
            Math.abs(treat.velocity.x);
          let Fy =
            -0.5 *
            Cd *
            A *
            rho *
            treat.velocity.y *
            treat.velocity.y *
            treat.velocity.y /
            Math.abs(treat.velocity.y);
    
          Fx = isNaN(Fx) ? 0 : Fx;
          Fy = isNaN(Fy) ? 0 : Fy;
    
          // Calculate acceleration ( F = ma )
          var ax = Fx / treat.mass;
          var ay = ag + Fy / treat.mass;
          // Integrate to get velocity
          treat.velocity.x += ax * frameRate;
          treat.velocity.y += ay * frameRate;
    
          // Integrate to get position
          treat.position.x += treat.velocity.x * frameRate * 100;
          treat.position.y += treat.velocity.y * frameRate * 100;
          
          treat.checkBounds();
          treat.update();
        },
        
        checkBounds() {
    
          if (treat.position.y > height - treat.radius) {
            treat.velocity.y *= treat.restitution;
            treat.position.y = height - treat.radius;
          }
          if (treat.position.x > width - treat.radius) {
            treat.velocity.x *= treat.restitution;
            treat.position.x = width - treat.radius;
            treat.direction = -1;
          }
          if (treat.position.x < treat.radius) {
            treat.velocity.x *= treat.restitution;
            treat.position.x = treat.radius;
            treat.direction = 1;
          }
    
        },
    
        update() {
          const relX = this.position.x - this.absolutePosition.x;
          const relY = this.position.y - this.absolutePosition.y;
    
          this.el.style.setProperty("--x", relX);
          this.el.style.setProperty("--y", relY);
          this.el.style.setProperty("--direction", this.direction);
        }
      };
    
      setTimeout(() => {
        treat.remove();
      }, lifetime);
    
      return treat;
    }
    
    
    function animationLoop() {
      var i = treats.length;
      while (i--) {
        treats[i].animate();
    
        if (!treats[i].animating) {
          treats.splice(i, 1);
        }
      }
    
      requestAnimationFrame(animationLoop);
    }
    
    animationLoop();
    
    function addTreats() {
      //cancelAnimationFrame(frame);
      if (treats.length > 10) {
        return;
      }
      for (let i = 0; i < 4; i++) {
        treats.push(createTreat());
      }
    }

    if(spree) {
      addTreats();
    } 
    

    setCounter(counter + 1)


    if (counter === 0) {


      //resno?! Od sedaj naprej ne pritiskaj več. OK?!
      setMsg(data[1].title)
      loader();


    } else if (counter === 1) {
      

      
      //Nekdo je za igro! Čim hitreje pritisni gumb!"
      setMsg(data[2].title)
      loader();

    } else if (counter === 2) {

      setSpree(true)
      //Opa strela! Se pardoniramo. No, pa ga pritisni 30x!
      setMsg(data[3].title)
      loader();
    } else if (counter === 32) {
      
      //Ok, kolikokrat pa ga lahko pritisneš v 10 sekundah
      setMsg(data[4].title)
      loader();

      setTimeout(() => {
        // Wooow, bravo, ti računalniška antilopa ti. Če pritisneš še 5x, ti že moramo povedati skrivnost.
        setMsg(data[5].title)
        setCounter(counter + 100);
        setBoosterValue(100);
        setBooster(true)
        loader();

      }, 5000);

    } else if (counter === 136) {
      setSpree(false)
      //Te zanima kaj je skrivnost?
      setMsg(data[6].title)
      loader();
    } else if (counter === 137) {
      //Skrivnost je … najbolje, da pritisneš in pogledaš.
      setMsg(data[7].title)
      loader();
    } else if (counter === 138) {
      //Do konca je 2022 klikov. Sori.
      setMsg(data[8].title)
      loader();
    } else if (counter === 139) {
      //Pritisni rdeč gumb
      setMsg(data[9].title)
      randomPositionButton("true")

    } else if (counter === 140) {
      randomPositionButton("false")
      //Pazi 
      setMsg(data[10].title)
      loader();
    } else if (counter === 141) {
      displaceButton("true", 20, 10)
      // Zdej
      setMsg(data[11].title)
      loader();
    } else if (counter === 142) {
      displaceButton("true", 60, 80)
      // To
      setMsg(data[12].title)
      loader();
    } else if (counter === 143) {
      displaceButton("false")
      hideButton("true")
      //Pritisni velik rdeč gumb … O je je!
      setMsg(data[13].title)
      loader();
    } else if (counter === 144) {
      hideButton("false")
      multiButton("true", 5)
      //Ufff, iz nič na šest. Če klikneš na pravilen gumb, dobiš 60 dodatnih klikov. Le kateri gumb je pravi? Klik, klik!
      setMsg(data[14].title)
      loader();
    } else if (counter === 145) {
      multiButton("false")
      setCounter(counter + 60);
      setBoosterValue(60);
      setBooster(true)
      multiButton("true", 8)
      //Pa zdaj? Za 180 klikov ... najdeš pravega?
      setMsg(data[15].title)
      loader();
    } else if (counter === 205) {
      setSpree(true)
      multiButton("false")

      //Wopaaa! Bravo! Naslednjih 5 klikov je vrednih 50
      setMsg(data[16].title)
      loader();
    } else if (counter === 210) {
      setCounter(counter + 250);
      setBoosterValue(250);
      setBooster(true)
      //Čestitke! Števec klikov je pokazal, da si že čez 500. Še 10x
      setMsg(data[17].title)
      loader();
    } else if (counter === 469) {
      setSpree(false)
      randomPositionButton("true")
      //650! A ga lahko ujameš? Nope. 1 klik ni dovolj
      setMsg(data[18].title)
      loader();
    } else if (counter === 474) {
      randomPositionButton("false")
      //Bravo! Če nadaljuješ, te nagradimo s 150 bonus kliki!
      setMsg(data[19].title)
      loader();
    }

    else if (counter === 475) {
      setSpree(true)
      setCounter(counter + 150);
      setBoosterValue(150);
      setBooster(true)

      //Nadaljujemo z nagradami. Samo danes nora ponudba! Za 50 klikov dobiš 50 klikov. 🙃

      setMsg(data[20].title)
      loader();
    }

    else if (counter === 675) {
      setSpree(false)
      setSpree(true)
      setCounter(counter + 50);
      setBoosterValue(50);
      setBooster(true)
      multiButton("true", 4)
      // 5 gumbov * 10
      setMsg(data[21].title)
      loader();
    }

    else if (counter === 726) {

      multiButton("false")
      multiButton("true", 2, "escapebox")
      setCounter(counter + 50);
      setBoosterValue(50);
      setBooster(true)
      //Pred tabo so trije gumbi. 40x klikni na gumb, ki se ujema z barvo Escapebox loga.
      setMsg(data[22].title)
      loader();
    }

    else if (counter === 816) {

      multiButton("false")
      //A te zanima koliko manjka do polovice? Klikni 17x
      setMsg(data[23].title)
      loader();
    }

    else if (counter === 833) {
      //In smooooo čez polovicooo. Od veselja klikni 30x! 
      setMsg(data[24].title)
      loader();
    }

    else if (counter === 863) {
      setSpree(false)
      randomPositionButton("true")
      //Zdej se ti ne splača več odnehat. Ujami gumb in 49 klikov je tvojih.
      setMsg(data[25].title)
      loader();
    }

    else if (counter === 864) {
      randomPositionButton("false")
      setCounter(counter + 49);
      setBoosterValue(49);
      setBooster(true)
      // Naprej
      setMsg(data[26].title)
      loader();
    }

    else if (counter === 914) {
      // Naprej
      setMsg(data[27].title)
      loader();
    }

    else if (counter === 915) {
      displaceButton("true", 10, 10)
      //+87 / button displace
      setMsg(data[28].title)
      loader();
    }

    else if (counter === 916) {
      displaceButton("false")
      displaceButton("true", 40, 40)
      setCounter(counter + 87);
      setBoosterValue(87);
      setBooster(true)
      // +80 / button displace
      setMsg(data[29].title)
      loader();
    }

    else if (counter === 1004) {
      displaceButton("false")
      setCounter(counter + 80);
      setBoosterValue(80);
      setBooster(true)
      // +10 / button center
      setMsg(data[30].title)
      loader();
    }

    else if (counter === 1085) {
      setSpree(true)
      displaceButton("false")
      displaceButton("true", 80, 10)
      // 40 klikov / gumb desno zgoraj
      setMsg(data[31].title)
      loader();
    }

    else if (counter === 1126) {
      displaceButton("false")
      displaceButton("true", 10, 80)
      // 40 klikov / gumb levo spodaj
      setMsg(data[32].title)
      loader();
    }

    else if (counter === 1166) {
      displaceButton("false")
      // 40 klikov / gumb centralno
      setMsg(data[33].title)
      loader();
    }

    else if (counter === 1207) {
      setSpree(false)
      multiButton("true", 2, "txtcolor")
      // +60 /  tri gumbi, pravilen v barvi besedila
      setMsg(data[34].title)
      loader();
    }

    else if (counter === 1208) {
      multiButton("false")
      multiButton("true", 2, "red")
      // +60 / pravilen gumb rdeč
      setMsg(data[35].title)
      loader();
    }

    else if (counter === 1209) {
      setSpree(true)
      multiButton("false")
      setCounter(counter + 60);
      setBoosterValue(60);
      setBooster(true)
      // 10 sekund / +100 klikov
      setMsg(data[36].title)

      setTimeout(() => {
        setSpree(false)
        setMsg(data[37].title)
        setCounter(counter + 100);
        setBoosterValue(100);
        setBooster(true)
        loader();

      }, 5000);

      loader();
    }

    else if (counter === 1313) {
      // +50
      setMsg(data[38].title)
      loader();
    }

    else if (counter === 1314) {
      setCounter(counter + 50);
      setBoosterValue(50);
      setBooster(true)
      // +50 
      setMsg(data[39].title)
      loader();
    }
    else if (counter === 1364) {
      
      // +1
      setMsg(data[40].title)
      loader();
    }
    else if (counter === 1365) {
      //  +200
      setMsg(data[41].title)
      loader();
    }

    else if (counter === 1366) {
      setCounter(counter + 200);
      setBoosterValue(200);
      setBooster(true)
      // +1
      setMsg(data[42].title)
      loader();
    }

    else if (counter === 1567) {
      setSpree(true)
      displaceButton("true", 75, 90)
      // 3 kliki / relocate
      setMsg(data[43].title)
      loader();
    }

    else if (counter === 1570) {
      setSpree(false)
      displaceButton("false")
      randomPositionButton("true")
      // + 40, random move
      setMsg(data[44].title)
      loader();
    }

    else if (counter === 1571) {
      randomPositionButton("false")
      hideButton("true")
      setCounter(counter + 40);
      setBoosterValue(40);
      setBooster(true)
      // + 50 / hidden
      setMsg(data[45].title)
      loader();
    }

    else if (counter === 1612) {
      setSpree(true)
      hideButton("false")
      setCounter(counter + 50);
      setBoosterValue(50);
      setBooster(true)
      // 12 klikov
      setMsg(data[46].title)
      loader();
    }

    else if (counter === 1674) {
      
      //  10 klikov 10 sekund
      setMsg(data[47].title)
      setCompleted(true)
      setDate(Date.now())
      loader();
    }



    else if (counter === 1684) {
      setCompleted(false)
      setMsg(data[48].title)
      setCounter(counter + 100);
      setBoosterValue(100);
      setBooster(true)
      loader();
    }
    

    else if (counter === 1784) {
      play();
      setSpree(false)
      setShowVideo(true)
      setMsg(data[49].title)
      loader();
    }



    else if (counter === 1775) {
      // sporočilo + form
      setMsg(data[50].title)
      loader();
    }


    if (counter > 1674) {
      setCounterLoc(counterLoc - 1)
    }


  }


  let submitHandler = async (e) => {
    e.preventDefault();
    try {

      let res = await fetch("https://api.escapebox.si/quizbox/user", {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: name,
          email: email,
          quizId: "p443o-ktr4ija8-00apo"
        }),
      });


      let resJson = await res.json();

      if (res.status === 201 || res.status === 200) {
        setName("");
        setEmail("");
        setMessage("User created successfully");
        setSent(true);

      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };


  function getRandomInt(max, min) {
    return Math.random() * (max - min) + min;
  }


  function appendNCopies(n, original, appendTo, type) {

    original.style.transition = "none";
    original.style.zIndex = "5";
    original.style.top = getRandomInt(0,90) + "%";
    original.style.bottom = "auto";
    original.style.left = getRandomInt(0,90) + "%";

    if(type == "escapebox") {
      appRef.current.classList.add("escapebox")
    } else if (type == "txtcolor") {
      appRef.current.classList.add("txtcolor")
    } else if (type == "red") {
      appRef.current.classList.add("redcolor")
    }


    for(var i = 0; i < n; i++) {

        var clone = original.cloneNode(true);
        clone.style.position = "absolute";
        clone.style.top = getRandomInt(0,90) + "%";
        clone.style.left = getRandomInt(0,90) + "%";
        clone.style.zIndex = "1";
        clone.classList.add("duplicated")

        
        appendTo.appendChild(clone);
    }



  }

  function hideButton(start) {
    

    if (start == "true") {
      buttonRef.current.style.opacity = "0";
    } else if (start == "false") {
      buttonRef.current.removeAttribute('style');
    }


  }


  function displaceButton(start, left, top) {
    
    if (start == "true") {
      buttonRef.current.style.position = "absolute";
      buttonRef.current.style.left = left + "%";
      buttonRef.current.style.top = top + "%";
    } else if (start == "false") {
      buttonRef.current.removeAttribute('style');
    }


  }

  function randomPositionButton(start) {

    let { width, height } = contentRef.current.getBoundingClientRect(); // gives you width, height, left-X,top-y of the button
    let buttonSize = buttonRef.current.getBoundingClientRect();

    const randomPos = () => {
      buttonRef.current.style.top = getRandomInt(height - buttonSize.height,buttonSize.height) + "px";
      buttonRef.current.style.left = getRandomInt(width - buttonSize.width,buttonSize.width) + "px";
      buttonRef.current.style.position = "absolute";
    }
    randomPos();
    if (start == "true") {
      let moveInterval = setInterval(() => {
        
        randomPos();
      }, 800);
      setIntervalval(moveInterval);

    } else if (start == "false") {
      clearInterval(intervalval);
      buttonRef.current.removeAttribute('style');
    }
  }

  function multiButton(start, num, type) {

    let { width, height } = contentRef.current.getBoundingClientRect(); // gives you width, height, left-X,top-y of the button
    let buttonSize = buttonRef.current.getBoundingClientRect();

    function getRandomInt(max, min) {
      return Math.random() * (max - min) + min;
    }

    if (start == "true") {
      
      appendNCopies(num, buttonRef.current, contentRef.current, type);
    } else if (start == "false") {
      const elements = document.getElementsByClassName("duplicated");
      while(elements.length > 0){
          elements[0].parentNode.removeChild(elements[0]);
      }
      buttonRef.current.removeAttribute('style');
      appRef.current.classList.remove("escapebox");
      appRef.current.classList.remove("redcolor");
      appRef.current.classList.remove("txtcolor");
    }
  }


  useEffect(() => {
    console.log(counter)

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

  });


  return ( 

    <>
    <Div100vh>
    
    <div className = "App " ref={appRef}>

        {booster 
        
        && 
        <div className="booster">
        <motion.div
          animate={{
            scale: [0, 1, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 1, 0],
            rotate: [90, 120, 0, 0, 0, 0, 0, 0, 0, 0, 120, 90],
          }}
          transition={{ duration: 1}}
        ><h1>+{boosterValue}</h1>
        </motion.div>
        </div>
        
        }

        
        {!showVideo ? (
        <>
        <div className="heading" >
          <h1>{msg}</h1>
          {completed && <div className="fcountdown">{counterLoc}</div>}
        </div>
        <div className="content" ref={contentRef}>

          <button
          className={`action-button ${loading ? "loading" : ""}`}
          onClick={clickHandler}
          ref={buttonRef}
          />
  
        </div>

        <div className="progressbar">
          <a href="https://www.escapebox.si" target="_blank"><img className="logo" src={imgLogo}  /></a>
          <ProgressBar animated now={counter / 17.75} /> 
        </div>
        </>
        )

        : (

        <>

        <div className="container">

          <div className="heading mt-4 mb-4" >
            <h1>OMG 🎉🎉  Nismo verjeli, da je kdo res tako nor, da bo priklikal do konca ❤️ </h1>
          </div>

          <div class="video-container mb-4">
            <video autoplay="autoplay" loop playsinline>
              <source src={process.env.PUBLIC_URL + '/video/ploskanje2.mp4'}/>
            </video>
          </div>
          
          <p>Zaslužiš si Certifikat najbolj (ne)kakovostno porabljenega časa v letu 2021!</p>

          <p>Za nagrado ti ne podarjamo ničesar 🤷🏼  </p>

          <p>Razen če nam zaupaš kdo si. Potem te 10. 1. 2022 morda izžrebamo za unikatno nagrado. Poskusi svojo (ne)srečo:</p>
          
          <div className="mt-4 pb-4">
          {sent 
            ? <h1>Poslano</h1>
            : (
              
                <form onSubmit={submitHandler}>
      
                  <input
                    required
                    type="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
      
      
                  <button className="btn" type="submit">Oddaj</button>
      
                  <div className="message">{message ? <p>{message}</p> : null}</div>
                </form>
      
            ) 
            }
            </div>
          </div>
          </>

        )
        }
        


    
    </div>
    </Div100vh>
    </>
  );
}


function App() {
  return (
    <>
    <Snowfall
      color="white"
      snowflakeCount={80}
    />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="igra" element={<Game />} />
    </Routes>
    </>
  );
}

export default App;